import React, { useState } from "react";
const counters = [
  { name: "Adults", defaultValue: 1 },
  { name: "Children", defaultValue: 0 },
  { name: "Infants", defaultValue: 0 },
];
let cabinType = "Economy";
const setCabinType = (value) => {
  cabinType = value;
};

const CabinClass = ({ onCabinChange }) => {
  const [cabin, setCabin] = useState("Economy");
  const handleCabinChange = (value) => {
    setCabin(value);
    setCabinType(value);
    onCabinChange(value);
  };

  const cabinClasses = [
    {
      title: "Economy",
      value: cabin,
      list: [
        {
          label: "Economy",
          optionValue: "Economy",
        },
        {
          label: "Premium Economy",
          optionValue: "Premium Economy",
        },
        {
          label: "Business Class",
          optionValue: "Business Class",
        }
      ],
      onChange: handleCabinChange,
    },
  ];

  return (
    <>
      <div className="row y-gap-10 justify-between items-center">
        <div className="col-auto">
          <div className="text-15 lh-12 fw-500">Cabin Class</div>
        </div>
        <div className="col-auto">
          {cabinClasses.map((option, index) => (
            <div className="col-auto" key={index}>
              <div className="dropdown js-dropdown">
                <div
                  className="dropdown__button d-flex items-center text-15"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="true"
                  data-bs-offset="0,0"
                >
                  <span className="js-dropdown-title">{option.value}</span>

                  <i className="icon icon-chevron-sm-down text-7 ml-10" />
                </div>
                <div className="toggle-element -dropdown js-click-dropdown dropdown-menu">
                  <div className="text-14 y-gap-15 js-dropdown-list">
                    {option.list.map((item, index) => (
                      <div key={index}>
                        <div
                          role="button"
                          className={`${
                            item.optionValue === option.value
                              ? "text-blue-1 "
                              : ""
                          }d-block js-dropdown-link`}
                          onClick={() => option.onChange(item.optionValue)}
                        >
                          {item.label}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const Counter = ({
  name,
  defaultValue,
  onCounterChange,
  guestCounts,
  setGuestCounts,
}) => {
  const [count, setCount] = useState(defaultValue);
  const totalAdultsChildren = 9;

  const incrementCount = () => {
    const countVal = count + 1;
    // setMinusBtn(false);
    setCount(countVal);
    onCounterChange(name, countVal);
  };

  const decrementCount = () => {
    const countVal = count - 1;
    if (count > 0) {
      setCount(countVal);
      onCounterChange(name, countVal);
    }
  };

  const disableDecreament = (name) => {
    if (name === "Adults") {
      return count <= 1;
    }

    if (name === "Children" || name === "Infants") {
      return count <= 0;
    }
  };

  const disableIncreament = (name) => {
    if (name === "Adults" || name === "Children") {
      return (
        (count >= 9) ||
        guestCounts.Adults + guestCounts.Children + 1 > 9
      );
    }

    if (name === "Infants") {
      return count >= guestCounts.Adults;
    }
  };

  return (
    <>
      <div className="row y-gap-10 justify-between items-center">
        <div className="col-auto">
          <div className="text-15 lh-12 fw-500">{name}</div>
          {name === "Adults" && (
            <div className="text-14 lh-12 text-light-1 mt-5">(12+ yrs)</div>
          )}
          {name === "Children" && (
            <div className="text-14 lh-12 text-light-1 mt-5">(2 - 11 yrs)</div>
          )}
          {name === "Infants" && (
            <div className="text-14 lh-12 text-light-1 mt-5">(Below 2 yrs)</div>
          )}
        </div>
        {/* End .col-auto */}
        <div className="col-auto">
          <div className="d-flex items-center js-counter">
            {/* className={`button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down ${
                minusBtn ? "disabled-btn" : ""
              }`} */}
            <button
              className={`button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down`}
              onClick={decrementCount}
              disabled={disableDecreament(name)}
            >
              {/* disabled={(name === "Adults") && (count <= 1)} */}
              <i className="icon-minus text-12" />
            </button>
            {/* decrement button */}
            <div className="flex-center size-20 ml-15 mr-15">
              <div className="text-15 js-count">{count}</div>
            </div>
            {/* counter text  */}
            <button
              className={`button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up`}
              onClick={incrementCount}
              disabled={disableIncreament(name)}
            >
              {/* disabled={(name === "Adults") && (count >= 9)} */}
              <i className="icon-plus text-12" />
            </button>
            {/* increment button */}
          </div>
        </div>
        {/* End .col-auto */}
      </div>
      {/* End .row */}
      <div className="border-top-light mt-24 mb-24" />
    </>
  );
};

const GuestSearch = (props) => {
  const [guestCounts, setGuestCounts] = useState({
    Adults: 1,
    Children: 0,
    Infants: 0,
  });
  const [ctype, setCType] = useState(cabinType);
  const handleCounterChange = (name, value) => {
    setGuestCounts((prevState) => ({ ...prevState, [name]: value }));
    props.setTravellers((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleCabinTypeChange = (value) => {
    setCType(value);
    props.setTravellers((prevState) => ({ ...prevState, ["cabin"]: value }));
  };

  // console.log('in handler', guestCounts);
  return (
    <div className="searchMenu-guests px-24 lg:py-20 lg:px-0 js-form-dd js-form-counters text-left">
      <div
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        data-bs-offset="0,22"
      >
        <h4 className="text-16 fw-500 ls-2 lh-16">
          Travellers <i className="icon icon-chevron-sm-down text-7 ml-10" />
        </h4>
        <div className="text-18 text-light-1 ls-2 lh-16">
          <span className="js-count-adult">{(guestCounts.Adults)+(guestCounts.Children)+(guestCounts.Infants)}</span> Travellers {","}<span className="js-count-room">{ctype}</span>
        </div>
      </div>
      {/* End guest */}
      {/*
      <div className="shadow-2 dropdown-menu min-width-400">
        <div className="bg-white px-30 py-30 rounded-4 counter-box">
          {counters.map((counter) => (
            <Counter
              key={counter.name}
              name={counter.name}
              defaultValue={counter.defaultValue}
              onCounterChange={handleCounterChange}
              guestCounts={guestCounts}
              setGuestCounts={setGuestCounts}
            />
          ))}
          <CabinClass onCabinChange={handleCabinTypeChange} />
        </div>
      </div>
     */}
    </div>
  );
};
export default GuestSearch;