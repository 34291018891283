import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import './signup.css';

function SignIn() {
    return (
        <>
            <Header />
            <section className="overflow-hidden">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="mw-md py-12 py-md-24 py-lg-40 px-5 mx-auto">
                            <h4 class="font-heading mb-4">Login</h4>
                            <p class="mb-8">
                                <span class="text-light-dark">New to TripRecharge?</span>
                                <Link class="d-inline-block text-dark border-0 fw-bold px-2" to="/register"> Create new account</Link>
                            </p>
                            <form action="">
                                <label className="form-label" for="">Email</label>
                                <input className="form-control mb-4" type="email" placeholder="john@email.com" />
                                <button className="btn mb-4 py-3 lh-lg w-100 btn-primary border-primary-dark">Login</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="py-12 py-md-16 py-lg-24 px-5 mw-xl mx-auto">
                            <div className="row mb-n4">
                                <div className="col-6 mb-4">
                                    <div className="d-inline-flex px-5 pt-14 rounded-3 bg-info">
                                        <img className="d-block mx-auto img-fluid" src="triprecharge-assets/images/sign-up/man-picture.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-6 mb-4">
                                    <div className="d-inline-flex h-100 w-100 px-5 pt-14 rounded-3 imgUp"></div>
                                </div>
                                <div className="col-6 mb-4">
                                    <div className="d-inline-flex h-100 w-100 px-5 pt-14 rounded-3 imgDown"></div>
                                </div>
                                <div className="col-6 mb-4">
                                    <img className="d-block img-fluid w-100 h-100 rounded-3" src="triprecharge-assets/images/sign-up/woman-picture.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default SignIn