import { Routes, Route } from "react-router-dom";
import Home from '../Home';
import SignUp from "../Authentication/signup";
import SignIn from "../Authentication/signin";

function Router() {
    return(
        <Routes>
            <Route path="/" element={
               <Home />
            } />
            <Route path="/register" element={
               <SignUp />
            } />
              <Route path="/login" element={
               <SignIn />
            } />
        </Routes>
    )
}

export default Router;