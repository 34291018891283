import Header from '../Header';
import Content from './content';
import Footer from '../Footer';

function Home(){
    return(
        <>
         <div className="">
           <div>
             <Header />
             <Content />
             <Footer />
           </div>
         </div>
        </>
    )
}

export default Home;