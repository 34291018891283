import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import './signup.css';

function SignUp() {
    return (
        <>
            <Header />
            <section className="overflow-hidden">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="mw-md py-12 py-md-24 py-lg-40 px-5 mx-auto">
                            <h4 className="font-heading mb-4">Sign up</h4>
                            <p className="mb-8">
                                <span className="text-light-dark">Already have an account?</span>
                                <Link className="d-inline-block text-dark border-0 fw-bold px-2" to="/login">Login</Link>
                            </p>
                            <form action="">
                                <label className="form-label" for="">Full Name</label>
                                <input className="form-control mb-4" type="text" placeholder="John Smith" />
                                <label className="form-label" for="">Your Email</label>
                                <input className="form-control mb-4" type="email" placeholder="john@email.com" />
                                <label className="form-label" for="">Phone Number</label>
                                <input className="form-control mb-4" type="number" placeholder="1234567890" />
                                <div class="mb-3">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inline-name" value="option 1"/>
                                        <label class="form-check-label " for="">I am a Traveller</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inline-name" value="option 2" />
                                        <label class="form-check-label " for="">I am an Agent</label>
                                    </div>
                                </div>
                                <div class="mb-3 form-check">
                                    <input class="form-check-input" type="checkbox" name="field-name" value="example value" />
                                    <label class="form-check-label " for="">
                                        <span>I agree to the <Link class="btn btn-link p-0 fs-9" href="#">Terms & Conditions</Link> of TripRecharge.</span>
                                    </label>
                                </div>
                                <button className="btn mb-4 py-3 lh-lg w-100 btn-primary border-primary-dark">Sign Up</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="py-12 py-md-16 py-lg-24 px-5 mw-xl mx-auto">
                            <div className="row mb-n4">
                                <div className="col-6 mb-4">
                                    <div className="d-inline-flex px-5 pt-14 rounded-3 bg-info">
                                        <img className="d-block mx-auto img-fluid" src="triprecharge-assets/images/sign-up/man-picture.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-6 mb-4">
                                    <div className="d-inline-flex h-100 w-100 px-5 pt-14 rounded-3 imgUp"></div>
                                </div>
                                <div className="col-6 mb-4">
                                    <div className="d-inline-flex h-100 w-100 px-5 pt-14 rounded-3 imgDown"></div>
                                </div>
                                <div className="col-6 mb-4">
                                    <img className="d-block img-fluid w-100 h-100 rounded-3" src="triprecharge-assets/images/sign-up/woman-picture.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default SignUp